import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Link } from 'react-router-dom'
import { AuthLayout } from '@/components/auth/AuthLayout'

export function VerifyEmailPage() {
  return (
    <AuthLayout>
      <div className="min-h-screen from-primary/5 to-background">
        <div className="container mx-auto px-4 py-16">
          <div className="mb-16 text-center">
            <p className="mt-4 text-xl text-muted-foreground">
              One last step to get started!
            </p>
          </div>
          <div className="mx-auto max-w-md">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Check Your Email</CardTitle>
                <CardDescription>We've sent you a verification link.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    Please check your inbox and click the link to verify your email address.
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Once verified, you can{' '}
                    <Link
                      to="/login"
                      className="text-primary underline-offset-4 hover:underline"
                    >
                      sign in
                    </Link>{' '}
                    to your account.
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
