import { useCallback, useEffect, useRef } from 'react'
import { useApiKeys } from './useApiKeys'
import { useAuth } from '@/components/auth/AuthProvider'

export function useIframeMessaging(iframeRef: React.RefObject<HTMLIFrameElement>, onConfigSent?: () => void) {
  const { keys } = useApiKeys()
  const { user, userBotData } = useAuth()
  console.log('[useIframeMessaging] Initialized with keys:', keys, 'user:', user, 'botData:', userBotData);
  const hasConfigured = useRef(false)

  const sendConfig = useCallback(() => {
    console.log('[Host] Attempting to send config to chat iframe...');
    
    if (!iframeRef.current?.contentWindow) {
      console.warn('[Host] Cannot send config: iframe contentWindow not available');
      return;
    }
    
    if (!keys.apiURL || !keys.nexusSecret) {
      console.warn('[Host] Cannot send config: missing apiURL or nexusSecret');
      return;
    }

    const config = {
      type: 'CONFIG_UPDATE',
      payload: {
        api: keys.apiURL,
        secret: keys.nexusSecret,
        context: {
          userId: user?.id,
          email: user?.email,
          metadata: user?.user_metadata,
          bot_associations: userBotData?.bot_associations
        }
      }
    }

    // Log config with redacted secret
    console.log('[Host] Sending config:', config);

    iframeRef.current.contentWindow.postMessage(config, '*')
    if (!hasConfigured.current) {
      hasConfigured.current = true;
      console.log('[Host] Config sent successfully');
      if (onConfigSent) {
        onConfigSent();
      }
    }
  }, [keys.apiURL, keys.nexusSecret, user, userBotData])

  useEffect(() => {
    console.log('[useIframeMessaging] Attaching message event listener');
    const handleMessage = (event: MessageEvent) => {
      console.log('[HOST] Got Message type:', event.data.type);
      sendConfig();
      if (event.data.type === 'CHAT_READY') {
        console.log('[Host] Received CHAT_READY message from iframe, sending config update...');
        sendConfig();
      }
    }

    window.addEventListener('message', handleMessage)
    
    return () => {
      console.log('[useIframeMessaging] Removing message event listener');
      window.removeEventListener('message', handleMessage)
    }
  }, [sendConfig])

  return { sendConfig }
}
