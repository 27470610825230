import { useRef } from 'react';
import { useIframeMessaging } from '@/lib/hooks/useIframeMessaging';
import { config } from '@/lib/config';

interface ChatFrameProps {
  agentId: string;
  agentName: string;
  sessionId: string;
  onConfigSent?: () => void;
}

export function ChatFrame({ agentId, agentName, sessionId, onConfigSent }: ChatFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useIframeMessaging(iframeRef, onConfigSent);
  const chatUrl = `${config.chatAppURL}?id=${agentId}&agentName=${encodeURIComponent(agentName)}&sessionId=${sessionId}&botIdPrefix=${agentId}&mfe=true`;

  return (
    <div className="h-[600px] rounded-lg border bg-background overflow-hidden">
      <iframe
        ref={iframeRef}
        src={chatUrl}
        className="h-full w-full border-none"
        title={`Chat with ${agentName}`}
        allow="microphone; camera"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals"
        loading="lazy"
        referrerPolicy="origin"
      />
    </div>
  );
}
