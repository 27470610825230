import { ChatContainer } from '@/components/chat/ChatContainer';

export function ChatPage() {
  return (
    <div className="space-y-8 p-4">
      <h2 className="text-3xl font-bold tracking-tight">Smart AI Assistants</h2>
      <ChatContainer />
    </div>
  );
}
