import { useState, useEffect } from 'react';
import { useAuth } from '@/components/auth/AuthProvider';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { supabase } from '@/lib/supabase';

export function ProfileSection() {
  const { user } = useAuth();
  const [email, setEmail] = useState(user?.email || '');
  const [error, setError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (user) {
      console.log('Current user:', user);
      console.log('User metadata:', user.user_metadata);
      console.log('Avatar URL:', user.user_metadata?.avatar_url);
      console.log('App metadata:', user.app_metadata);
    }
  }, [user]);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsUpdating(true);
    setError('');

    try {
      const { error } = await supabase.auth.updateUser({
        email,
        data: {
          updated_at: new Date().toISOString(),
        },
      });
      if (error) throw error;
    } catch (error) {
      setError('Failed to update profile');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profile</CardTitle>
        <CardDescription>Manage your profile information</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center space-x-4">
          <Avatar className="h-16 w-16">
            {user?.user_metadata?.avatar_url ? (
              <AvatarImage src={user.user_metadata.avatar_url} alt="Avatar" />
            ) : (
              <AvatarFallback>{user?.email?.[0]?.toUpperCase()}</AvatarFallback>
            )}
          </Avatar>
          <div>
            <h3 className="font-medium">{user?.user_metadata?.full_name || 'User'}</h3>
            <p className="text-sm text-muted-foreground">{user?.email}</p>
          </div>
        </div>

        <div className="space-y-2">
          <h4 className="font-medium">Metadata</h4>
          <Card className="bg-muted">
            <CardContent className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-muted-foreground">
                    <strong>Full Name:</strong> {user?.user_metadata?.full_name}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    <strong>Email:</strong> {user?.email}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    <strong>Email Verified:</strong> {String(user?.user_metadata?.email_verified)}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    <strong>Phone Verified:</strong> {String(user?.user_metadata?.phone_verified)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">
                    <strong>Provider ID:</strong> {user?.user_metadata?.provider_id}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    <strong>Issuer:</strong> {user?.user_metadata?.iss}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    <strong>Subject:</strong> {user?.user_metadata?.sub}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <form onSubmit={handleUpdateProfile} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="email" className="text-sm font-medium">
              Email
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>

          {error && <p className="text-sm text-red-500">{error}</p>}

          <Button type="submit" disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update Profile'}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
