import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { User } from '@supabase/supabase-js'
import { supabase } from '@/lib/supabase'
import { LoadingScreen } from '../ui/loading-screen'
import { config } from '@/lib/config'
import { useNavigate } from 'react-router-dom'
import { ApiErrorPage } from '@/pages/auth/ApiErrorPage'
import { STORAGE_KEYS } from '@/lib/constants/storage-keys'

interface AuthContextType {
  user: User | null
  loading: boolean
  error: string | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string | null>>
  isInitialized: boolean
  retryInitialization: () => Promise<boolean>
  userBotData: any | null
  setUserBotData: React.Dispatch<React.SetStateAction<any | null>>
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  error: null,
  setUser: () => {},
  setLoading: () => {},
  setError: () => {},
  isInitialized: false,
  retryInitialization: async () => false,
  userBotData: null,
  setUserBotData: () => {},
})

async function initializeUser(supabaseToken: string, signal?: AbortSignal) {
  const response = await fetch(`${config.apiURL}/auth/supabase/initialize_user`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${supabaseToken}`,
      'Content-Type': 'application/json'
    },
    signal
  });

  if (!response.ok) {
    throw new Error('Failed to initialize user');
  }

  return await response.json();
}

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [currentSession, setCurrentSession] = useState<any>(null)
  const [userBotData, setUserBotData] = useState<any | null>(null)
  const initializationStarted = useRef(false)
  const abortController = useRef<AbortController | null>(null)

  const navigate = useNavigate()
  // Removed Broadcast messaging integration:
  // const broadcast = useBroadcastMessaging();
  // console.log('[AuthProvider] Broadcast messaging initialized', broadcast);

  const handleInitializeUser = async (session: any) => {
    if (initializationStarted.current) {
      console.log('Initialization already in progress, skipping...')
      return false
    }

    initializationStarted.current = true
    
    // Cancel any pending request
    if (abortController.current) {
      abortController.current.abort()
    }
    abortController.current = new AbortController()
    
    try {
      const responseData = await initializeUser(session.access_token, abortController.current.signal)
      setUserBotData(responseData)
      
      // Save the API key to localStorage for use in iframe messaging
      if (responseData.api_key) {
        console.log('[Auth] Saving API key to localStorage')
        localStorage.setItem(STORAGE_KEYS.NEXUS_SECRET, responseData.api_key)
      } else {
        console.warn('[Auth] No API key found in initialization response')
      }
      
      setIsInitialized(true)
      setError(null)
      return true // Indicate successful initialization
    } catch (error: any) {
      console.error('Error initializing user:', error)
      
      if (error.name === 'AbortError') {
        console.log('Initialization aborted')
        return false
      }
      
      if (error.message === 'Failed to initialize user') {
        // Treat as uninvited user
        setError(null) // Clear any existing error so API error page doesn't show
        navigate('/uninvited')
      } else {
        // For other errors (network issues, server down, etc), show the API error page
        setError('Unable to connect to the API. Please check your connection and try again.')
      }
      throw error // Re-throw to be caught by handleUser
    } finally {
      initializationStarted.current = false
    }
  }

  const retryInitialization = async () => {
    if (currentSession) {
      setLoading(true)
      setError(null)
      try {
        const initializationSuccessful = await handleInitializeUser(currentSession)
        if (initializationSuccessful) {
          setUser(currentSession.user)
          return true
        } else {
          setUser(null)
        }
      } catch (error) {
        console.error('Error during retry initialization:', error)
        setUser(null)
      } finally {
        setLoading(false)
      }
    }
    return false
  }

  const handleUser = async (user: User | null, session: any) => {
    if (user) {
      // Only proceed if there's a change in user ID or session
      if (!currentSession?.user?.id || currentSession.user.id !== user.id || !isInitialized) {
        console.log('User state:', user)
        console.log('User metadata:', user.user_metadata)
        console.log('App metadata:', user.app_metadata)

        setCurrentSession(session)
        setLoading(true)
        
        try {
          const initializationSuccessful = await handleInitializeUser(session)
          if (initializationSuccessful) {
            setUser(user)
          } else {
            console.log('User initialization failed or was aborted')
            setUser(null)
          }
        } catch (error) {
          console.error('Error during user initialization:', error)
          setUser(null)
        }
      } else {
        console.log('User already initialized, skipping...')
      }
    } else {
      setUser(null)
      setIsInitialized(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      handleUser(session?.user || null, session)
    })

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, session) => {
      handleUser(session?.user || null, session)
    })

    return () => subscription.unsubscribe()
  }, [navigate])

  if (loading && !error) {
    return <LoadingScreen />
  }

  if (error) {
    return <ApiErrorPage message={error} onRetry={retryInitialization} />
  }

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      error,
      setUser,
      setLoading,
      setError,
      isInitialized,
      retryInitialization,
      userBotData,
      setUserBotData
    }}>
      {children}
    </AuthContext.Provider>
  )
}
