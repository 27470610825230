import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"

export function WelcomePage() {
  const navigate = useNavigate()

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-6 rounded-lg border bg-background p-8 shadow-lg">
        <div className="space-y-2 text-center">
          <h1 className="text-3xl font-bold">Welcome to BotNexus!</h1>
          <p className="text-muted-foreground">
            Your AI Teaching Assistant Platform
          </p>
        </div>
        <div className="space-y-4">
          <Button 
            onClick={() => navigate("/login")} 
            className="w-full"
          >
            Login
          </Button>
          <Button 
            onClick={() => navigate("/signup")} 
            variant="outline"
            className="w-full"
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  )
}
