import { useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Key, Copy, Check } from 'lucide-react'
import { SecretInput } from '@/components/settings/SecretInput'
import { useApiKeys } from '@/lib/hooks/useApiKeys'

export function ApiKeysSection() {
  const { keys, saveKeys } = useApiKeys()
  const [formKeys, setFormKeys] = useState(keys)
  const [isUpdating, setIsUpdating] = useState(false)

  const [copied, setCopied] = useState(false)

  const handleCopySecret = async () => {
    if (formKeys.nexusSecret) {
      try {
        await navigator.clipboard.writeText(formKeys.nexusSecret)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      } catch (error) {
        console.error("Failed to copy secret key:", error)
      }
    }
  }

  const handleSaveKeys = async () => {
    setIsUpdating(true)
    try {
      await saveKeys(formKeys)
      // Add a small delay to show the updating state
      setTimeout(() => {
        setIsUpdating(false)
      }, 500)
    } catch (error) {
      console.error('Failed to save API keys:', error)
      setIsUpdating(false)
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Key className="h-5 w-5" />
          API Configuration
        </CardTitle>
        <CardDescription>
          Manage your Nexus API connection settings
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="apiURL" className="text-sm font-medium">
            Nexus API Host
          </label>
          <Input
            id="apiURL"
            type="text"
            value={formKeys.apiURL}
            onChange={(e) => setFormKeys(prev => ({ ...prev, apiURL: e.target.value }))}
            placeholder="Enter your Nexus API host (e.g., https://api.nexus.example.com)"
          />
        </div>

        <div className="space-y-2">
          <SecretInput
            id="nexusSecret"
            value={formKeys.nexusSecret}
            onChange={(value) => setFormKeys(prev => ({ ...prev, nexusSecret: value }))}
            placeholder="Enter your Nexus secret key"
            label="Nexus Secret Key"
          />
          <div className="flex justify-end">
            <Button variant="outline" onClick={handleCopySecret}>
              {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
            </Button>
          </div>
        </div>

        <Button 
          onClick={handleSaveKeys} 
          disabled={isUpdating}
          className="w-full"
        >
          {isUpdating ? 'Saving...' : 'Save Configuration'}
        </Button>

        <p className="text-sm text-muted-foreground">
          Your API configuration is stored securely in your browser's local storage.
          These settings are used to connect to your Nexus API instance.
        </p>
      </CardContent>
    </Card>
  )
}
