
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface Agent {
  id: string
  name: string
  description: string
  avatar?: string
  isActive: boolean
  microFrontendUrl?: string
}

export interface Organization {
  id: string
  name: string
  agents: Agent[]
  stats: {
    studentCount: number
    teamMemberCount: number
    totalChats: number
  }
  settings: {
    theme: 'light' | 'dark' | 'system'
    allowedDomains?: string[]
  }
}

interface OrgState {
  currentOrg: Organization
  setCurrentOrg: (org: Organization) => void
  updateOrgSettings: (settings: Partial<Organization['settings']>) => void
  toggleAgentStatus: (agentId: string) => void
}

const defaultOrg: Organization = {
  id: '',
  name: '',
  agents: [],
  stats: {
    studentCount: 0,
    teamMemberCount: 0,
    totalChats: 0,
  },
  settings: {
    theme: 'system',
  },
}

export const useOrgStore = create<OrgState>()(
  persist(
    (set) => ({
      currentOrg: defaultOrg,
      setCurrentOrg: (org) => set({ currentOrg: org }),
      updateOrgSettings: (settings) =>
        set((state) => ({
          currentOrg: {
            ...state.currentOrg,
            settings: { ...state.currentOrg.settings, ...settings },
          },
        })),
      toggleAgentStatus: (agentId) =>
        set((state) => ({
          currentOrg: {
            ...state.currentOrg,
            agents: state.currentOrg.agents.map((agent) =>
              agent.id === agentId
                ? { ...agent, isActive: !agent.isActive }
                : agent
            ),
          },
        })),
    }),
    {
      name: 'org-store',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            currentOrg: {
              ...defaultOrg,
              ...persistedState?.currentOrg,
            },
          }
        }
        return persistedState
      },
    }
  )
)
