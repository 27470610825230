import { useOrgStore } from '@/lib/store/org-store'
import { MoonIcon, SunIcon } from '@radix-ui/react-icons'
import { Button } from '../ui/button'
import { useAuth } from '../auth/AuthProvider'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import { Link } from 'react-router-dom'
import { Search, Bell } from 'lucide-react'
import { AppVersion } from './AppVersion'
import { Logo } from '../ui/logo'

interface DashboardHeaderProps {
  children?: React.ReactNode
}

export function DashboardHeader({ children }: DashboardHeaderProps) {
  const { currentOrg, updateOrgSettings } = useOrgStore()
  const { user } = useAuth()

  const toggleTheme = () => {
    const newTheme = currentOrg.settings.theme === 'dark' ? 'light' : 'dark'
    updateOrgSettings({ theme: newTheme })
    document.documentElement.classList.toggle('dark')
  }

  return (
    <header className="sticky top-0 z-40 border-b bg-background">
      <div className="container grid h-16 items-center grid-cols-3 py-4">
        <div className="flex items-center gap-4">
          {children}
          <Link to="/dashboard">
            <Logo />
          </Link>
          <AppVersion />
        </div>
        <div className="hidden md:block">
          <div className="relative w-full max-w-sm">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
            <input
              type="search"
              placeholder="Search..."
              className="w-full rounded-full bg-muted px-10 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          <Button variant="ghost" size="icon" className="relative">
            <Bell className="h-5 w-5" />
            <span className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-[10px] text-white">
              3
            </span>
          </Button>
          
          <Button variant="ghost" size="icon" onClick={toggleTheme}>
            {currentOrg.settings.theme === 'dark' ? (
              <SunIcon className="h-5 w-5" />
            ) : (
              <MoonIcon className="h-5 w-5" />
            )}
          </Button>
          
          <Link to="/dashboard/settings">
            <Avatar className="h-8 w-8 cursor-pointer">
              <AvatarImage src={user?.user_metadata?.avatar_url} />
              <AvatarFallback>
                {user?.email?.[0]?.toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </Link>
        </div>
      </div>
    </header>
  )
}