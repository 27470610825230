import { Button } from '@/components/ui/button'

interface ApiErrorPageProps {
  message?: string
  onRetry?: () => void
}

export function ApiErrorPage({ message, onRetry }: ApiErrorPageProps) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-4 rounded-lg border bg-background p-6 shadow-lg">
        <h2 className="text-2xl font-bold text-destructive">API Connection Error</h2>
        <p className="text-muted-foreground">
          {message || 'Unable to connect to the API. Please check your connection and try again.'}
        </p>
        {onRetry && (
          <Button
            onClick={onRetry}
            className="w-full"
          >
            Retry Connection
          </Button>
        )}
      </div>
    </div>
  )
}
