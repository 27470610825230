import { useState, useCallback } from 'react'
import { STORAGE_KEYS } from '../constants/storage-keys'
import { config } from '../config'

interface ApiKeys {
  apiURL: string
  nexusSecret: string
}

export function useApiKeys() {
  const [keys, setKeys] = useState<ApiKeys>(() => ({
    apiURL: config.apiURL || '',
    nexusSecret: localStorage.getItem(STORAGE_KEYS.NEXUS_SECRET) || '',
  }))

  const saveKeys = useCallback(async (newKeys: ApiKeys) => {
    try {
      // Only save secret to localStorage, apiURL comes from env
      localStorage.setItem(STORAGE_KEYS.NEXUS_SECRET, newKeys.nexusSecret)
      setKeys(newKeys)
      return true
    } catch (error) {
      console.error('Failed to save API keys:', error)
      return false
    }
  }, [])

  return {
    keys,
    saveKeys,
  }
}
