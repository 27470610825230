import { Button } from "@/components/ui/button"
import { useAuth } from "@/components/auth/AuthProvider"
import { AuthLayout } from "@/components/auth/AuthLayout"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { MoonIcon, SunIcon } from "@radix-ui/react-icons"
import { useOrgStore } from "@/lib/store/org-store"
import { supabase } from "@/lib/supabase"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export function UninvitedPage() {
  const { retryInitialization, user } = useAuth()
  const { currentOrg, updateOrgSettings } = useOrgStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const toggleTheme = () => {
    const newTheme = currentOrg.settings.theme === 'dark' ? 'light' : 'dark'
    updateOrgSettings({ theme: newTheme })
    document.documentElement.classList.toggle('dark')
  }

  const handleSignOut = async () => {
    await supabase.auth.signOut()
    navigate('/')
  }

  const handleCheckAgain = async () => {
    setLoading(true)
    setError(null)
    try {
      const success = await retryInitialization()
      if (success) {
        navigate('/dashboard/chat')
      } else {
        setError("Your account is still not activated. Please try again later.")
      }
    } catch (err) {
      setError("An error occurred. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <div className="flex flex-col space-y-6 relative">
        <div className="absolute right-0 -top-12 flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleTheme}
          >
            {currentOrg.settings.theme === 'dark' ? (
              <SunIcon className="h-5 w-5" />
            ) : (
              <MoonIcon className="h-5 w-5" />
            )}
          </Button>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="h-8 w-8 cursor-pointer">
                <AvatarImage src={user?.user_metadata?.avatar_url} />
                <AvatarFallback>
                  {user?.email?.[0]?.toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem disabled>
                {user?.email}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleSignOut}>
                Sign out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-2xl font-semibold tracking-tight">
            Invitation Required
          </h1>
          <p className="text-sm text-muted-foreground">
            Your account requires an invitation to access this platform.
            Please contact your administrator for access.
          </p>
        </div>

        <div className="grid gap-4">
          <Button onClick={handleCheckAgain} disabled={loading}>
            {loading ? "Checking..." : "Check Again"}
          </Button>
          {error && (
            <p className="text-sm text-red-500 text-center">{error}</p>
          )}
        </div>

        <div className="mt-4 text-center text-sm text-muted-foreground">
          <p>
            If you believe this is an error or need assistance,
            please contact us at office@botnexus.ai
          </p>
        </div>
      </div>
    </AuthLayout>
  )
}
