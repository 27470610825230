import "../../../src/index.css";
import { useOrgStore } from '@/lib/store/org-store'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Bot, MessageSquare, Users, GraduationCap } from 'lucide-react'
import { useEffect } from 'react'
//import { usePostHog } from 'posthog-js/react'

export function DashboardPage() {
  const org = useOrgStore((state) => state.currentOrg)
  //const posthog = usePostHog()

  const customSurvey = {
    id: "0195003e-7366-0000-d38e-b8fc2485f942",
    name: "Open feedback",
    description: "",
    type: "api",
    linked_flag_key: null,
    targeting_flag_key: null,
    questions: [
      {
        type: "open",
        question: "What can we do to improve our product?",
        description: "",
        originalQuestionIndex: 0,
        descriptionContentType: "text"
      }
    ],
    conditions: null,
    start_date: null,
    end_date: null
  };

  /* Disabled custom survey rendering
  useEffect(() => {
    console.log("Attempting to render custom survey with id:", customSurvey.id);
    const container = document.querySelector("#posthog-survey-container");
    if (!container) {
      console.error("Survey container not found");
    } else {
      console.log("Survey container found:", container);
      posthog.renderSurvey(customSurvey.id, '#posthog-survey-container');
      console.log("Survey rendered, capturing survey shown event");
      posthog.capture("survey shown", { $survey_id: customSurvey.id });
      setTimeout(() => {
        console.log("Survey container contents after render:", container.innerHTML);
      }, 1000);
    }
  }, [posthog]);
  */

  if (!org) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <p>Loading...</p>
      </div>
    )
  }

  const activeAgents = org.agents.filter((agent) => agent.isActive)

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Active Agents</CardTitle>
            <Bot className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{activeAgents.length}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Chats</CardTitle>
            <MessageSquare className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">+{org.stats.totalChats}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Team Members</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{org.stats.teamMemberCount}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Students</CardTitle>
            <GraduationCap className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{org.stats.studentCount}</div>
          </CardContent>
        </Card>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {org.agents.map((agent) => (
          <Card key={agent.id}>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-lg">
                <Bot className="h-5 w-5" />
                {agent.name}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">{agent.description}</p>
              <div className="mt-2">
                <span
                  className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                    agent.isActive
                      ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300'
                      : 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300'
                  }`}
                >
                  {agent.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      <div id="posthog-survey-container" style={{ minHeight: '300px', width: '100%', display: 'none', border: '2px dashed blue' }}></div>
    </div>
  )
}