import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AppRoutes } from './components/auth/AppRoutes'
import { ErrorBoundary } from './components/auth/ErrorBoundary'
import { AuthProvider } from './components/auth/AuthProvider'
import { PostHogProvider } from 'posthog-js/react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  const options = { api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <PostHogProvider 
            apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY} 
            options={options}
          >
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </PostHogProvider>
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
